import { toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, createSlots as _createSlots, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.getEditor && _ctx.getEditor.component)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getEditor.component), _mergeProps({ key: 0 }, Object.assign({}, _ctx.prop, _ctx.prop.attrs, _ctx.getEditor.attrs), {
          component: _ctx.getEditor,
          value: _ctx.value,
          onChange: _ctx.propChangeListener
        }), _createSlots({
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getEditor.html) + " ", 1)
          ]),
          _: 2
        }, [
          _renderList(Object.keys(_ctx.getEditor.slot ?? {}), (slot) => {
            return {
              name: slot,
              fn: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.getEditor.slot ?? {})[slot], (detailComponent, index3) => {
                  return (_openBlock(), _createBlock(_resolveDynamicComponent(detailComponent.component), _mergeProps(detailComponent.attrs, {
                    key: slot + detailComponent.component + index3
                  }), _createSlots({
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(detailComponent.html) + " ", 1)
                    ]),
                    _: 2
                  }, [
                    _renderList(Object.keys(detailComponent.slot), (detailSlot) => {
                      return {
                        name: detailSlot,
                        fn: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detailComponent.slot[detailSlot], (detail2Component, index4) => {
                            return (_openBlock(), _createBlock(_resolveDynamicComponent(detail2Component.component), _mergeProps(detail2Component.attrs, {
                              key: detailSlot + detail2Component.component + index4
                            }), {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(detail2Component.html), 1)
                              ]),
                              _: 2
                            }, 1040))
                          }), 128))
                        ])
                      }
                    })
                  ]), 1040))
                }), 128))
              ])
            }
          })
        ]), 1040, ["component", "value", "onChange"]))
      : _createCommentVNode("", true)
  ]))
}