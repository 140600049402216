import { state as globalState } from '@/modules/global';
import { MedicalRole } from '@/modules/medical/@types/doctor-manage';

export const useRole = () => {
  /** 判断当前用户角色是否包含医生 */
  const judgeRoleIsDoc = () => {
    return globalState.userInfo.roles.includes(MedicalRole.doctor);
  };
  /** 判断当前用户角色是否包含医生端凝动管理员 */
  const judgeRoleIsMAd = () => {
    return globalState.userInfo.roles.includes(MedicalRole.medicalNdylAdmin);
  };
  /** 判断当前用户角色是否包含医生管理员 */
  const judgeRoleIsHAd = () => {
    return globalState.userInfo.roles.includes(MedicalRole.hospitalAdministrator);
  };

  return {
    judgeRoleIsDoc,
    judgeRoleIsMAd,
    judgeRoleIsHAd,
  };
};
